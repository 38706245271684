import styled from 'styled-components';

export const StyledNotFound = styled.div`
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.white};
  background-color: transparent;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  height: 100vh;
`;

export const Text = styled.span`
  display: block;
  font-size: 72px;
  line-height: 125%;
  font-weight: 900;

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 144px;
  }
`;

export const SmallText = styled.span`
  display: block;
  margin-bottom: 60px;
  font-size: 18px;
  line-height: 125%;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 45px;
  }
`;